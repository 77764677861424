require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

const images = require.context("../images", true);
const imagePath = name => images(name, true);

import * as Sentry from "@sentry/browser";
Sentry.init({ dsn: "https://5bacb386806744e6bcf09d865142e9f3@sentry.io/5184546" });

import "stylesheets/application";
import "react-toastify/dist/ReactToastify.css";
import "ldbutton/dist/ldbtn.css";
import "alpinejs";
